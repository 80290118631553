@import "../variables";

.table-view {
    width: 100%;
    border-collapse: collapse;
    color: $text-grey;

    th, td {
      padding: 16px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      width: 50px;
    }
  
    th {
      background-color:   
   #f2f2f2;
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 30%;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 32%;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 30%;
    }
  
    tr:nth-child(odd) {
      background-color: #E6F2EE;
    }

    .header {
        font-size: 18px;
        font-weight: 700;
        line-height: 25.2px;
        letter-spacing: -0.01em;
        text-align: left;
        background-color: white;
    }

    .link-container {
        display: flex;
        align-items: center;

        .link {
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            letter-spacing: -0.01em;
            text-align: left;
            text-decoration: underline;
            color: $primary;
            cursor: pointer;
        }

        img {
            cursor: pointer;
            margin-left: 24px;
        }
    }

    .data {
      font-size: 16px;
      font-weight: 700;
      line-height: 22.4px;
      letter-spacing: -0.01em;
      text-align: left;
    }

    .row-data {
      color: $text-grey;
    }


    .disabled-info {
      font-size: 16px;
      font-weight: 700;
      line-height: 18.4px;
      text-align: left;
      color: #A03021;
      margin-right: 32px;
    }

    .infomation {
      display: flex;
      align-items: center;

    }


}