.page .identity-verification-submission-success.layout-content {
  padding: 48px 68px;
  max-width: 1280px;
  .title {
    margin-bottom: 24px;
  }
  .lead-text {
    max-width: 849px;
  }
  .text:not(.lead-text) {
    letter-spacing: -0.01rem;
  }
  .finish-btn {
    margin-top: 8px;
    margin-bottom: 32px;
  }
  .find-pei-btn {
    margin-top: 16px;
    width: fit-content;
  }
}
