$primary: #007960;
$secondary: #4a4a4a;
$light-font: 300;
$medium-font: 400;
$heavy-font: 700;
$border: #d8d8d8;
$primary-background: #ffffff;
$primary-border-color: #000000;
$primary-border-radius: 4px;
$verified-me-background: #000b6f;
$layout-padding: 25px;
$warning: #b30900;
$valid: #385d0e;
$green: #1c7e66;
$green-light: #E6F2EE;
$primary-green: #008570;
$error: #a03021;
$error-background: #f3e9e8;
$red: #a03021;
$text-grey: #344054;
$text-light-grey: #667085;
// colors
$primary-blue: #005db8;
$dark-blue: #004fb3;
$reminder-blue: #245269;
$light-blue: #c5dfff;
$light-blue-1: #e2efff;
$black: #000000;
$black-dark: #473d66;
$black-dark-1: #091b31;
$black-dark-2: #001d39;
$black-light: #232323;
$black-light-1: #3d3d3d;
$white: #ffffff;
$gray: #4a4a4a;
$gray-light: #d8d8d8;
$gray-light-1: #6c6c6c;
$gray-light-2: #757575;
$gray-light-3: #e2e2e2;
$gray-light-4: #f2f2f2;
$gray-light-5: #454545;
$gray-light-6: #737373;
$gray-light-7: #e5e5e5;
$gray-light-8: #646464;
$gray-dark: #473d66;
$gray-dark-1: #333333;
$gray-dark-2: #777777;
$gray-dark-3: #667085;
$purple: #473d66;
$footer-background: #008579;
$red-light: rgba(179, 9, 0, 0.15);
$red-light-2: #fff4f4;
$header-background: #e2f0f8;
$header-bottom-background: #088571;
$soft-green: #e6f2ee;
$input-error-border: #9D3427;

// font-family
$font-family: -apple-system, BlinkMacSystemFont, sans-serif;
$font-family-lato: 'Lato', sans-serif;
$font-family-open-sans: 'Open Sans', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-arial: 'Arial', sans-serif;
$font-family-georgia: 'Georgia', sans-serif;
$font-family-work-sans: 'Work Sans', sans-serif;
// font weight
$regular: 400;
$bold: 700;
