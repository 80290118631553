@import '../partials/variables';

.wallet-consent-page {
  padding-top: 16px;

  @include respond(medium) {
    padding-top: 0px;
  }
  .title {
    color: $text-grey;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: -0.04em;
    max-width: 1150px;
    line-height: 39.6px;
    text-align: left;
    margin-bottom: 24px;

    @include respond(medium) {
      font-size: 64px;
      line-height: 57.6px;

      margin-bottom: 22px;
    }
  }

  .subtitle {
    font-size: 2rem;
    margin: 40px 0 24px 0;
    font-size: 32px;
    font-weight: 500;
    color: #232323;
  }

  .wallet-consent-btn {
    & > button {
      margin-right: 24px;
    }
  }

  .wallet-consent-subtitle {
    color: $text-grey;
    max-width: 849px;
    font-family: $font-family-work-sans;
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: -0.01em;
  }

  .wallet-consent-auth {
    color: $text-grey;
    margin-top: 8px;
    @include respond(medium) {
      margin-top: 14px;
      margin-bottom: 16px;
    }
  }

  .wallet-consent-link {
    color: $primary;
    font-family: $font-family-work-sans;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    text-align: left;
    font-weight: 400;
    cursor: pointer;
    margin-top: 0px;

    @include respond(medium) {
      margin-top: 0px;
    }
  }

  .wallet-consent-link--config {
    margin-top: 32px;
  }


  .wallet-consent-section {
    display: flex;
    max-width: 1150px;
    justify-content: space-between;
  }

  .wallet-consent-section-primary {
    max-width: 654px;
    min-width: 300px;
  }

  .wallet-consent-button-banner {
    display: flex;
    justify-content: space-between;
  }

  .wallet-consent-section-secondary {
    max-width: 458px;
    margin-bottom: 24px;
    display: none;
    
    @include respond(medium) {
      display: block;
      margin-bottom: 0px;
      margin-top: -60px;
    }
  }

  .wallet-consent-section-secondary--mobile {
    display: block;
    max-width: none;

    > img {
      width: 100%;
    }

    @include respond(medium) {
      display: none;
    }
  }

.wallet-consent-terms-privacy {
  display: flex;
  margin-top: 10px;
  margin-bottom: 32px;
  line-height: 22.4px;

  a {
    display: inline;
  }
}

  .wallet-consent-profile {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 32px;
    font-family: $font-family-work-sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    text-align: left;

    & > img {
      margin-right: 12px;
    }
  }

.wallet-consent-body {
  display: flex;
}

.wallet-consent-body-text {
  max-width: 751px;
  margin-right: 2px;
  margin-bottom: 32px;
}
.wallet-consent-config-body-text {
  max-width: 751px;
  margin-right: 2px;
  margin-bottom: 32px;

  p:last-child {
    margin-top: 16px;
    margin-bottom: 0px;
  }
  p {
    line-height: 22.4px;
    letter-spacing: -0.01em;
    margin-bottom: 8px;

    word-break: break-word;

    a {
      display: inline;
    }
  }
}
}
.wallet-success-page {
  color: $text-grey;
  font-family: $font-family-open-sans;

  .wallet-success-page-desc {
    font-size: 20px;
  }

  .wallet-success-page-btn {
    margin-top: 32px;

    min-width: 100%;

    @include respond(medium) {
      min-width: 216px;
    }
  }
}
