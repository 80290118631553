@import "../variables";
.edit-drawer {
    font-family: $font-family-open-sans;
    .edit-drawer-top {
        display: flex;
        justify-content: space-between;
        padding-top: 21px;
        padding-bottom: 4px;
        span {
            align-self: center;
            font-size: 0.875rem;
            color: $black-light;
            font-weight: 600;
        }
        button {
            font-size: 16px;
            font-weight: 600;
            color: $primary-blue;
            padding-right: 10px;
            font-family: $font-family-open-sans;
        }
        &.warning {
            button {
                color: $warning;
            }
        }
    }
    .edit-drawer-bottom {
        color: $black-light;
        .textbox-form {
            margin-top: 4px;
            margin-bottom: 0 !important;
            label {
                margin: 0;
            }
        }
    }
    .primary-btn {
        padding-left: 50px;
        padding-right: 50px;
        width: fit-content;
        float: right;
        margin-bottom: 16px;
    }
}