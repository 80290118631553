//Breakpoints for different screens
@mixin respond($breakpoint) {

  @if $breakpoint==xxxxSmall {
    @media only screen and (max-width: 280px) {
      @content
    }
  }

  @if $breakpoint==xxxSmall-2 {
    @media only screen and (max-width: 317px) {
      @content
    }
  }

  @if $breakpoint==xxxSmall {
    @media only screen and (max-width: 330px) {
      @content
    }
  }

  @if $breakpoint==xxxSmall-mid {
    @media only screen and (max-width: 346px) {
      @content
    }
  }

  @if $breakpoint==xxSmall-2 {
    @media only screen and (max-width: 360px) {
      @content
    }
  }

  @if $breakpoint==xxSmall {
    @media only screen and (max-width: 376px) {
      @content
    }
  }

  @if $breakpoint==xxSmall-min {
    @media only screen and (min-width: 376px) {
      @content
    }
  }

  @if $breakpoint==xxSmall-min-2 {
    @media only screen and (max-width: 395px) {
      @content
    }
  }

  @if $breakpoint==xSmall {
    @media only screen and (max-width: 440px) {
      @content
    }
  }

  @if $breakpoint==xSmall-mid-2 {
    @media only screen and (max-width: 464px) {
      @content
    }
  }

  @if $breakpoint==xSmall-mid {
    @media only screen and (max-width: 500px) {
      @content
    }
  }


  @if $breakpoint==phone {
    @media only screen and (max-width: 600px) {
      @content
    }
  }

  @if $breakpoint==phone-med {
    @media only screen and (min-width: 460px) and (max-width: 600px) {
      @content
    }
  }

  @if $breakpoint==med-tab-port {
    @media only screen and (max-width: 725px) {
      @content
    }
  }

  @if $breakpoint==tab-port {
    @media only screen and (max-width: 900px) {
      @content
    }
  }

  @if $breakpoint==tab-port-high {
    @media only screen and (max-width: 960px) {
      @content
    }
  }

  @if $breakpoint==medium {
    @media only screen and (min-width: 601px) {
      @content
    }
  }


  @if $breakpoint==large {
    @media only screen and (min-width: 1280px) {
      @content
    }
  }

  @if $breakpoint==tab-land {
    @media only screen and (max-width: 1200px) {
      @content
    }
  }

  @if $breakpoint==big-desktop {
    @media only screen and (min-width: 1800px) {
      @content
    }
  }

  @if $breakpoint==medium-device {
    @media only screen and (min-width: 1200px) {
      @content
    }
  }

  @if $breakpoint==min-phone {
    @media only screen and (max-width: 760px) {
      @content
    }
  }

  @if $breakpoint==max-phone {
    @media only screen and (max-width: 712px) {
      @content
    }
  }

  @if $breakpoint==med-iphone-height {
    @media only screen and (max-height: 680px) {
      @content
    }
  }

  @if $breakpoint==mediumHeight {
    @media only screen and (max-height: 680px) and (min-width: 725px) {
      @content
    }
  }
}