.access-form {
	background-color: $green-light;
	padding: 32px 24px;
	margin-top: 42px;
	font-family: $font-family-work-sans;
	border-radius: 4px;

	h2 {
		font-size: 1.563rem;
		font-weight: 500;
		line-height: 1.1;		
		letter-spacing: -0.063rem;
		color: #006d57;
		margin-bottom: 15px;
	}

	p {
		color: $text-grey;
		font-size: 1rem;
		line-height: 1.4;
		letter-spacing: -0.01rem;
	}

	.verif-code {
		flex-direction: column;

		.textbox-form {
			margin-top: 24px;
			max-width: 555px;

			label {
				font-weight: bold;
			}

			input {
				width: 100%;
				border-radius: 8px;
			}
		}
		.verif-code-input-container {
			margin-bottom: 24px;
		}
	}
}
