@import '../variables';
@import '../mixins';
.page {
  display: grid;
  min-height: 100dvh;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(0, 1fr);
  .back-btn {
    width: fit-content;
    margin-top: 0;
    margin-bottom: 34px;
    font-size: 1rem;
  }
  &.footer {
    grid-template-rows: 1fr auto;
  }
  &.header {
    grid-template-rows: auto 1fr;
    &.footer {
      grid-template-rows: auto 1fr auto;
    }
  }

  .layout-content {
    box-sizing: border-box;
    padding: 38px 33px;
    .primary-btn {
      + .link-btn {
        margin-top: 30px !important;
        margin-bottom: 0 !important;
      }
    }
    &.progress-bar {
      padding-top: 16px;
    }
  }

  header {
    .header-main {
      display: flex;
      gap: 24px;

      @include respond(phone) {
        img:first-of-type {
          height: 51px;
        }

        img {
          max-width: 100px;
        }
      }

      @include respond(medium) {
        padding-left: 25px;

        img:first-of-type {
          height: 68px;
        }

        img {
          max-width: 140px;
        }
      }
    }
  }

  @include respond(medium) {
    header {
      .header-main {
        span {
          font-size: 1.625em;
        }
        svg {
          width: 41px;
          height: 41px;
        }
      }
    }
    .login-passcode {
      h1 {
        font-size: 1.875em !important;
      }
    }
    &.wa-setup-login-form-layout {
      margin-top: 0 !important;
    }

    .layout-content {
      max-width: 1210px;
      margin: 0px auto;
      width: 100%;
      padding-bottom: 64px;
      padding-left: 33px;
      padding-right: 33px;
      padding-top: 30px;

      &.progress-bar {
        padding-top: 16px !important;
      }
      &.success-setup,
      &.verified-me-completed {
        h1 {
          font-size: 2.25em !important;
        }
      }
      &.wa-setup-login-form {
        margin-top: 0 !important;
        padding-top: 0 !important;
        align-self: center;
        p {
          font-size: 1.375em;
        }
      }
      &[class*='account-settings'] {
        margin-top: 0 !important;
      }
      button {
        &.login-button {
          min-width: 107px !important;
          align-self: center;
          max-width: fit-content;
        }
      }
      .digit-textbox {
        margin-top: 32px;
      }
    }
    .wa-setup-header-m {
      display: none;
    }
    .wa-setup-header-d {
      display: block;
    }
  }
  @include respond(phone) {
    .layout-content {
      width: 100%;
      margin: 0px auto;
      padding: 16px 35px;
      box-sizing: border-box;
    }

    div[class*='account-settings'] {
      .link-btn {
        margin-bottom: 30px;
      }
    }
    .wa-setup-header-m {
      display: block;
    }
    .wa-setup-header-d {
      display: none;
    }
  }
}
