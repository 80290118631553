@import '../variables';
.card {
  background-color: $green-light;
  //   max-height: 165px;
  min-height: 209px; //263px -32px(top) - 22px(bottom)
  padding: 28px 20px 18px 20px;
  text-align: left;
  position: relative;
  border-radius: 4px;
  border: 4px solid $green-light;
  display: flex;

  &:focus-visible {
    border-color: $primary;
  }

  &.outline {
    background-color: #fff;
  }

  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: -0.01rem;
    color: $text-grey;
    font-family: 'Work Sans';
    .description {
      display: flex;
      flex-direction: column;
      gap: 8px;
      p {
        font-size: 1rem;
        line-height: 1.375rem;
        letter-spacing: -0.01rem;
      }
    }
  }

  h4 {
    color: $primary;
    max-width: 266px;
    font-size: 1.75rem;
    line-height: 1.1;
    font-weight: 500;
    letter-spacing: -0.07rem;
    font-family: 'Work Sans', sans-serif;
    @include respond(medium) {
      max-width: unset;
      line-height: 1;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .reminder {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon {
    position: absolute;
    display: flex;
    right: 16px;
    bottom: 20px;
    height: fit-content;
  }

  .reminder:only-child {
    justify-content: flex-end;
  }

  .body-reminder {
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: #fff;
    padding: 2px 13px 3px 13px;
    border: 1px solid $gray-dark-3;
    border-radius: 70px;
    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: -0.00875rem;
    }
  }
  @include respond(medium) {
    min-height: 167px; // 221px -22px(bottom) -32px(top)
    .body {
      width: 100%;
    }
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 32px;
  row-gap: 16px;
}

@include respond(medium) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 32px;
  }
}
