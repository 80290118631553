@import "../variables";

.textbox-form-input.dropdown {
    height: 48px;
    border: 1px solid $gray-light-3;
    width: auto;
    .dropdown-form {
        width: calc(100% - 62px);
        border: none;
    }
    // select {
    //     width: calc(100% - 62px);
    //     border: none;
    //     height: 100%;
    //     padding: 13px 12px;
    // }
    // option {
    //     background-color: pink;
    // }
}
