@import "../variables";

.loading-component {
    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }
  }

.dot-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 22px;
    min-width: 54px;
}

.dot-flashing-before-container {
    min-width: 10px;
    margin-right: 12px;
}

.dot-flashing-container {
    min-width: 10px;
}

.dot-flashing-after-container {
    min-width: 10px;
    margin-left: 12px;
}

.dot-flashing-before {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #E6F2EE;
    color: #E6F2EE;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #E6F2EE;
    color: #E6F2EE;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.dot-flashing-after {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #E6F2EE;
    color: #E6F2EE;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing {
    0% {
    background-color: white;
    width: 10px;
    height: 10px;
    }
    50%, 100% {
    background-color: #E6F2EE;

    }
}

