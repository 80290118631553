@import '../variables';
div[class*='login-form'] {
  &:not([class*='-layout']) {
    padding: $layout-padding;
    display: flex;
    flex-direction: column;
    h1 {
      text-align: center;
      margin-top: 20px;
      font-weight: 700 !important;
      font-family: $font-family-lato;
      font-size: 1.5rem;
    }
    p {
      font-family: $font-family-open-sans;
      margin-bottom: 24px;
      text-align: center;
      strong {
        font-weight: 700;
      }
    }
    .wa-setup-login-form-ota-icon {
      align-self: center;
      svg {
        height: 58px;
        width: 58px;
        display: block;
      }
    }
    .login-button {
      margin-bottom: 16px;
    }
    .primary-btn {
      border-radius: 8px;
      &:first-of-type {
        margin-bottom: 16px;
      }
      &:nth-of-type(2) {
        border-radius: 8px;
        background-color: white;
        color: $primary-blue;
        border: 1px solid $primary-blue;
        margin-bottom: 33px;
      }
      + .link-btn {
        text-decoration: underline;
        font-family: $font-family-open-sans;
        margin-top: 0 !important;
      }
    }
  }
}
