@import '../partials/variables';
@import '../partials/mixins';

.account-setup-help-container {
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  li {
    list-style-type: initial;
    list-style-position: inside;
  }
  a {
    display: inline;
    text-decoration: underline;
    text-underline-offset: 0.15rem;
    &:focus-visible {
      outline: 1px solid $primary;
    }
  }
  .title {
    margin-bottom: 0;
  }
  .grid-cols-2 {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 32px;
    row-gap: 32px;
    @include respond(medium) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  .mobile-screencap-container {
    display: flex;
    justify-content: center;
    row-gap: 1rem;
    flex-wrap: wrap;
    @include respond(medium) {
      justify-content: space-between;
    }
  }
  .mobile-screencap {
    height: 461px;
  }
  .screencap-caption {
    margin-top: 1rem;
    text-align: center;
    @include respond(medium) {
      text-align: left;
    }
  }
  .category {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .category-title {
      font-weight: 500;
      font-family: $font-family-work-sans;
      color: #344054;
      font-size: 2rem;
      line-height: 1.1;
      letter-spacing: -0.08rem;
      @include respond(medium) {
        font-size: 2.875rem;
        line-height: 1;
        letter-spacing: -0.115rem;
      }
    }
    .category-group {
      display: flex;
      flex-direction: column;
      gap: 32px;
      .group-title {
        color: #344054;
        font-family: $font-family-work-sans;
        font-weight: 500 !important;
        font-size: 1.75rem;
        line-height: 1.1;
        letter-spacing: -0.07rem;
        @include respond(medium) {
          line-height: 1;
        }
      }
      .group-notice {
        padding: 16px 13px;
        background-color: #e2efff;
        font-family: $font-family-work-sans;
        border-radius: 4px;
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: -0.01rem;
        a {
          display: inline;
        }
        @include respond(medium) {
          padding: 16px 32px;
        }
      }
      .accordion-content .notice {
        margin-top: 32px;
        background-color: #fef2e6;
        padding: 16px 19px;
        max-width: 286px;
      }
      .accordions {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .heading {
        font-weight: 700;
        color: #007960;
        font-family: $font-family-work-sans;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: -0.01rem;
        margin-bottom: 14px;
        margin-top: 16px;
      }
    }
  }
}
