@import "../variables";
div[class*="tos"] {
  &:not([class*="-layout"]) {
    padding: $layout-padding;
    display: flex;
    flex-direction: column;
    h1 {
      text-align: left;
      font-weight: 700 !important;
      font-size: 1.875rem;
    }
    .toggle-text-list {
      margin-top: 20px !important;
    }
    h2 {
      margin-top: 20px;
      margin-bottom: 14px;
      font-size: 1.4rem;
      font-weight: 400;
    }
    p {
      font-weight: 400;
      font-family: $font-family-open-sans;
    }
    h1,
    h2 {
      font-family: $font-family-lato;
    }
    .primary-btn {
      margin-top: 38px;
    }
    .notification {
      margin-top: 20px;
    }
  }
}
