@import "../variables";
.toggle-text-list {
    .toggle-text-list-title {
        display: flex;
        align-items: center;
        i {
            display: flex;
        }
        p {
            color: $primary-blue;
            font-weight: 600 !important;
            font-size: 1.2em;
        }
        &:not(.no-circle) {
            i {
                margin-right: 12px;
            }
            p {
                text-decoration: underline;
            }
        }
        &.no-circle {
            display: flex;
            width: 100%;
            i {
                margin-right: 9px;
            }
        }
    }
    .toggle-text-list-text {
        font-family: $font-family-open-sans;
        margin: 0px 0 0 32px;
        &.open {
            margin-top: 15px;
        }
        ol, ul {
            padding-left: 18px;
            li {
                margin-bottom: 10px;
                list-style-type: inherit;
            }
        }
    }
}