@import "../variables";
h1 {
	&.title {
		color: $text-grey;
		font-family: $font-family-work-sans;
		font-size: 36px;
		font-weight: 500;
		line-height: 39.6px;
		letter-spacing: -0.04em;


	}
	@include respond(medium) {
		&.title {
			font-size: 64px;
			font-weight: 500;
			line-height: 57.6px;
			letter-spacing: -0.04em;
		}
	}
}
