@import '../variables';
.icon-info-item {
  padding: 24px 0 2px;
  display: flex;
  align-items: center;
  gap: 12px;
  i {
    display: inline-flex;
    width: min-content;
  }
  &:not(.complete) {
    p {
      strong {
        font-weight: 700;
      }
    }
  }
  p {
    font-weight: 400;
  }
  &.complete {
    p {
      color: $gray-light-8 !important;
    }
  }
  a:hover {
    text-decoration: underline;
  }
}
