@import '../variables';
.digit-textbox-6 {
  width: 100% !important;
  text-align: center;
  input {
    font-family: $font-family-work-sans !important;
    border: none !important;
    border-bottom: 3px solid $text-grey !important;
    margin-right: 26px;
    border-radius: 0 !important;
    color: $text-grey !important;
    font-weight: $bold;
    padding: 0 !important;
    display: inline;
    width: 22px !important;
    font-size: 2.25rem !important;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}
