@import '../variables';
header {
  width: 100%;
  background-color: $primary;


  @include respond(phone) {
    .header-container {

      height: 82px;
      margin: 0 33px;

    }
  }

  @include respond(medium) {
    .header-container {

      height: 100px;
      margin: 0 26px;
      

    }
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    

    .info-btn {
      position: unset;
    }
  }

  .menu-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: none;
    padding-top: 20px;
    background-color: $white;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.18);
  }

  .primary-menu-list {
    &:last-child {
      padding-bottom: 26px;
    }
  }

  .primary-menu-list {
    border: none;
  }
  .menu-container .primary-menu-list:not(:last-child) {
    border: none;
  }
  .menu-container .primary-menu-list-item {
    padding: 12px 32px;
    border: none;
  }

  .primary-menu-list-item,
  .menu-list-button {
    font-size: 0.875rem;
  }

  .menu-container .menu-list-button span {
    color: $primary;
  }

  .header-logout {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    padding: 12px 24px;
    font-family: $font-family-work-sans;
    margin-left: 10px;
  }

  .header-button {
    font-size: 1rem;
    font-weight: 400;
    padding: 12px 0;
    font-family: $font-family-work-sans;
  }

  .header-buttons {
    display: flex;
    gap: 32px;
  }

  @include respond(phone) {
    .header-buttons {
      display: none;
    }
  }

  .header-signout {
    text-decoration: none;
    font-family: $font-family-work-sans;
    text-decoration: underline;
    padding: 0px;
    margin-right: 0;
  }

  @include respond(medium) {
    .header-container {
      align-items: center;
      display: flex;
      margin: 0px auto;
      max-width: 1210px;
      width: 100%;
      border-bottom: none;
      padding: 0px 33px;
      box-sizing: border-box;
    }

    .header-mobile {
      display: none;
    }

    .header-container .info-btn {
      display: none;
    }
  }
}
