@import "../variables";



.table-card-view {

  width: 100%;
  

  span:nth-child(odd) {
    background-color: #E6F2EE;
  }

  .card-view-container {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #ddd;
    color: $text-grey;
  }
  
  .disabled {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.4px;
    text-align: left;
    margin-bottom: 8px;
    color: #A03021;
  }

  .who {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    text-align: left;
    text-decoration: underline;
    color: $primary;
    margin-bottom: 8px;

  }

  .type {
    margin-bottom: 8px;
  }

  .lastActive {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    text-align: left;

  }
}