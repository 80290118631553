@import "../variables";

.radiobutton-container {
    display: grid;
    grid-template-areas: "input label" ". image";
    grid-template-columns: 30px 1fr;
    margin-bottom: 25px;
    align-items: center;
    label {
        font-family: $font-family-open-sans;
        grid-area: label;
        + svg {
            margin-top: 10px;
        }
    }
    img {
        grid-area: image;
    }
    input {
        grid-area: input;
        margin-top: 0;
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        appearance: none;
        display: inline-block;
        width: 16px;
        height: 16px;
        padding: 4px;
        background-clip: content-box;
        border: 1px solid $primary-blue;
        background-color: $white;
        border-radius: 50%;
        position: relative;
    }
    .radiobutton-image {
        margin-left: 29px;
    }
    i {
        display: flex;
    }

    input[type="radio"]:checked:after {
        background-color: $primary-blue;
        content: "";
        border-radius: 50%;
        height: 8px;
        width: 8px;
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
    }
}
