@import '../variables';
.icon-list-item {
  padding: 10px 0;
  display: flex;
  align-items: flex-start;
  i {
    margin-left: 1px;
    display: inline-flex;
    padding-right: 8px;
    min-width: 22px;
    align-items: center;
    justify-content: center;
  }
  &:not(.complete) {
    p {
      line-height: 1.375rem;
      strong {
        font-weight: 700;
      }
    }
  }
  p {
    font-weight: 400;
    letter-spacing: -0.01rem;
    max-width: 950px;
  }
  &.complete {
    p {
      color: $gray-light-8 !important;
    }
  }
}
