@import "../variables";
button {
    &.verified-me {
        background-color: $verified-me-background;
        padding: 0;
        span,
        i {
            flex: 1;
        }
        span {
            color: $white;
        }
        i {
            display: inline-flex;
            padding: 14px 16px;
            justify-content: center;
            background-color: $white;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}