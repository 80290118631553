@import '../variables';
.menu-container {
  font-family: $font-family-work-sans;
  .child-menu-list {
    padding-bottom: 18px;
    li {
      &:first-child {
        .child-menu-list-item {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }
  .primary-menu-list-item,
  .child-menu-list-item {
    padding: 12px 16px 16px 0;
    li {
      div {
        display: flex;
        justify-content: space-between;
      }
    }
    i {
      &.primary-menu-list-item-icon,
      &.child-menu-list-item-icon {
        width: 20px;
        min-width: 20px;
        max-width: 20px;
        text-align: center;
        display: inline-block;
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
    }
    a,
    div.menu-list {
      width: 100%;
      position: relative;
      display: flex;
      &.menu-arrow {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 4.5L0 9L0 0L5 4.5Z' fill='%230066CC'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 98% center;
        .left-box {
          display: flex;
        }
        .right-box {
          font-size: 0.875rem;
          text-transform: uppercase;
          align-items: center;
          display: flex;
          p {
            font-weight: $bold;
          }
          svg {
            margin-right: 12px;
            width: 16px;
            height: 16px;
          }
        }
      }
      span {
        font-weight: 400;
      }
    }
    .no-icon {
    }
  }
  .child-menu-list-item {
    padding-top: 25px;
  }
  .primary-menu-list-item {
    padding-top: 28px;
    padding-bottom: 28px;
    display: flex;
    justify-content: space-between;
    a,
    div.menu-list {
      span {
      }
    }
  }
  .primary-menu-list {
    &:not(:last-child) {
      border-bottom: 1px solid $gray-light;
    }
  }
  span {
    color: $black-light;
  }
}
