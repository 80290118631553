@import '../variables';

.list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 82px;
  font-family: $font-family-work-sans;
  border: 1px solid #ebebeb;
  border-width: 0px 0px 2px 0px;

  .description {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  .list-title {
    font-weight: 500;
    font-size: 20px;
  }

  .list-value {
    font-size: 16px;
    margin-top: 10px;
  }

  .list-action {
    width: 50px;
    height: 50px;
    margin-top: 4px;
    color: #007960;
    cursor: pointer;
    justify-content: center;
    display: flex;
  }
  .question {
    align-items: center;
    margin-right: 0px;
  }
}
