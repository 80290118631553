@import "../variables";

.wallet-layout {
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

.wallet-identity-unverified {
  font-family: $font-family-work-sans;

  .wallet-identity-unverified-subtitle {
    padding-top: 10px;
  }

  .wallet-identity-desc {
    margin: 32px 0px 16px 0px;
  }

  .wallet-identity-desc-1 {
    margin: 16px 0px 16px 0px;
  }

  .wallet-list-container ol {
    margin-left: 20px; /* Indent the list */
    padding-left: 0; /* Remove default list markers */
  }
  
  .wallet-list-container ol li {
    list-style-type: decimal;
    margin-bottom: 10px; /* Add spacing between list items */
  }

  .wallet-identity-pending-dec {
    margin: 32px 0px 14px 0px;
  }

  .wallet-identity-unverified-btn1 {
    margin: 8px 0px 0px 0px;
    min-width: 268px;

    @include respond(phone) {
      min-width: 100%;
    }
  }

  .wallet-identity-unverified-btn2 {
    margin: 14px 0px 36px 0px;
    min-width: 268px;
    @include respond(phone) {
      min-width: 100%;
    }

  }

  .wallet-identity-unverified-link {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    color: #007960;
    cursor: pointer;
  }

  .wallet-identity-unverified-subtitle {
    font-size: 20px;
  }
  @include respond(phone) {
  }

}
.wallet-identity-verified {
  font-family: $font-family-work-sans;
  .wallet-identity-verified-subtitle {
    padding-top: 10px;
    margin-bottom: 32px;
    font-size: 20px;
  }

  .wallet-identity-verified-btn{
    min-width: 230px;
  }

}
