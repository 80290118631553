@import '../variables';
.box-message {
  display: block;
  background-color: $red-light-2;
  padding: 16px;
  border-radius: 4px;
  color: $black-light;
  font-size: 14px;
  &.icon {
    display: flex;
  }
  i {
    display: flex;
    margin-right: 12px;
  }
  p {
    font-weight: 400;
  }
  &.error {
    background-color: $red-light-2;
    padding: 16px;
    color: $text-grey;
    font-family: $font-family-work-sans;
  }
}
