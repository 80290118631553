@import "../variables";

.box-button {
    font-family: $font-family-open-sans;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-light;
    span {
        align-self: center;
    }
    button {
        font-size: 16px;
        font-family: $font-family-open-sans;
        background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 4.5L0 9L0 0L5 4.5Z' fill='%230066CC'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 98% center;
        display: flex;
        align-items: center;
        font-weight: 500;
        text-transform: uppercase;
        padding: 21px 22px 21px 21px ;
        font-family: $font-family-roboto;
        i {
            height: 100%;
            svg {
                height: 100%;
                max-width: 16px;
                padding-right: 6px;
            }
        }
    }
    &.decline {
        button {
            color: $warning;
        }
    }
    &.valid {
        button {
            color: $valid;
        }
    }
}
