@import "../variables";

.review-account-layout {
    .review-account {
        padding: $layout-padding;
        display: flex;
        flex-direction: column;
        img {
            max-height: 200px;
            object-fit: contain;
            margin-bottom: 24px;
        }
        h1 {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 16px;
            font-weight: 400 !important;
            font-family: $font-family-lato;
        }
        .review-account-icon {
            align-self: center;
            svg {
                height: 58px;
                width: 58px;
                display: inline-block;
            }
        }
        .textbox-form {
            margin-top: 21px;
            &:last-of-type {
                margin-bottom: 31px;
            }
            &.remove-icon {
                i {
                    display: none;
                }
                input {
                    padding: 13px;
                }
            }
        }
        .link-btn {
            &:not(.back-btn){
                margin-top: 30px;
            }
            p {
                font-weight: 600;
            }
        }
        .disabled-text {
            color: $gray-light-1;
        }
        p {
            font-weight: 400;
        }
        >p:nth-of-type(2n + 1) {
            margin-bottom: 14px;
        }
        >p:nth-of-type(1) {
            margin-bottom: 18px;
        }
        h2,
        p {
            font-family: $font-family-open-sans;
        }
    }
}