@import "../variables";

.info-btn {
    position: absolute;
    right: 9px;
    top: 8px;
    circle {
        fill: $gray-dark-1;
    }
}
