@import "../variables";
div[class*="consent-form"] {
  &:not([class*="-layout"]) {
    padding: $layout-padding;
    display: flex;
    flex-direction: column;
    h1 {
      font-weight: 700 !important;
      text-align: left;
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.375rem;
      margin-bottom: 12px;
    }
    h1,
    h2 {
      font-family: $font-family-lato;
    }
    p {
      font-family: $font-family-open-sans;
    }
    .toggle-text-list {
      margin-top: 25px;
      margin-bottom: 20px;
    }
    .checkbox-container {
      margin-left: -4px;
      label {
        margin-left: 15px;
      }
    }
    .primary-btn {
      margin-top: 30px;
    }
    .notification {
      margin-bottom: 20px;
    }
  }
}
