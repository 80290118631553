@import '../variables';

.link-btn:not(.primary-btn) {
  color: $primary;
  font-size: 1rem;
  line-height: 22px;
  font-family: $font-family-work-sans;
  letter-spacing: -0.01rem;
  // text-decoration: underline;
  width: fit-content;
  text-align: left;
  cursor: auto;
  span {
    cursor: pointer;
  }
  &.decline {
    color: $warning;
  }
  &:focus-visible,
  &:hover {
    text-decoration: underline;
  }
}
