@import '../variables';
@import '../mixins';

.accordion-trigger {
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border-radius: 4px;
  border: 1px solid $primary;
  width: 100%;
  background-color: $green-light;
  color: $text-grey;
  font-weight: 500;
  font-family: $font-family-work-sans;
  font-size: 1.375rem;
  line-height: 1.1;
  letter-spacing: -0.055rem;
  padding: 16px 64px 16px 14px;
  @include respond(medium) {
    font-size: 1.5625rem;
    letter-spacing: -0.0625rem;
    padding: 26px 64px 24px 34px;
  }
  .chevron-container {
    width: 40px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: absolute;
    right: 24px;
    .chevron-icon {
      transition: all ease-in-out 300ms;
      &.rotate-180 {
        transform: rotate(180deg);
      }
    }
  }
}
.accordion-content {
  display: grid;
  overflow: hidden;
  transition: all ease-in-out 300ms;
  grid-template-rows: 0fr;
  opacity: 0;
  border: 1px solid $primary;
  border-top: 0;
  transform: translateY(-4px);
  font-family: $font-family-work-sans;
  color: $text-grey;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: -0.01rem;
  a {
    display: none !important;
  }
  ul {
    margin-block-end: 15px;
  }
  p:not(:last-child) {
    margin-block-end: 0.5rem;
  }
  &.show {
    grid-template-rows: 1fr;
    opacity: 100;
    a {
      display: inline !important;
    }
  }
  .accordion-content-container {
    overflow: hidden;
  }
  .accordion-content-inner {
    padding: 36px 14px 32px 14px;
    @include respond(medium) {
      padding-top: 36px;
      padding: 32px;
    }
  }
}
