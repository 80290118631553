@import "../variables";
button {
    &.login-button {
        border: 2px solid $primary-border-color;
        border-radius: $primary-border-radius;
        padding: 14px 16px;
        align-items: center;
        display: flex;
        width: 100%;
        span {
            font-family: $font-family-open-sans;
            text-align: center;
            width: 100%;
            font-weight: 700;
            line-height: 22px;
            font-size: 1rem;
        }
    }
    &.verified-me {
        background-color: $verified-me-background;
        padding: 0;
        span,
        i {
            flex: 1;
        }
        span {
            color: $white;
        }
        i {
            display: inline-flex;
            padding: 14px 16px;
            background-color: $white;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}