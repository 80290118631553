@import '../variables';

.box {
  font-family: $font-family-georgia;
  color: $red;
  display: flex;
  justify-content: space-between;
  padding: 21px 0;
  font-size: 1.5rem;
  span {
    align-self: center;
  }
  button {
    font-size: 16px;
    font-weight: $bold;
    color: $primary-blue;
    padding-right: 10px;
    font-family: $font-family-work-sans;
  }
  &.decline {
    button {
      color: $warning;
    }
  }
  &.valid {
    button {
      color: $valid;
    }
  }
}
