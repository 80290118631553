
.profile-information {

    h1 {
        margin-bottom: 40px!important;
    }
    .sub-title {
        color: $red;
        font-family: $font-family-georgia;
        font-size: 1.5rem;
        margin-bottom: 32px;
    }

    .textbox-form-container-3,
    .textbox-form-container-2 {
        border-bottom: 1px solid #D8D8D8;
        padding-bottom: 26px;
        margin-bottom: 26px;

        .textbox-form {
            margin-top: 3px;

            .active-border {
                > input {
                    border: 2px solid $primary;
                }
            }
        }

        .password-input {
            margin-bottom: 24px;
            margin-top: 0;
            max-width: 600px;

            > label {
                margin-bottom: 16px;
            }
        }

        .info-btn {
            top: -16px;
            right: 3px;
        }

        .text-btn {
            top: -10px;
            font-size: 1rem;
            color: $primary;
            font-weight: 700;
        }

        &.activated-name-form {
            .info-btn {
                top: 8px;
                right: 9px;
            }

            > .btn-group {
                margin-top: 8px;

                @include respond(phone) {
                    margin-top: 24px;
                }
            }
        }

        .primary-btn {
            margin-top: 4px;
        }
    }

    .textbox-form-container-2 {
        .textbox-form {
            > label {
                margin-bottom: 12px;
            }

            @include respond(phone) { 
                margin-top: 0;
            }
        }
    }

    /*
    .cancel-btn {
        color: $primary;
        font-size: 1rem;
        font-weight: 700;
        min-width: 50px;
        min-height: 54px;
        margin-right: 20px;
        padding: 16px 30px;
        border: 2px solid $primary;
        border-radius: 4px;
        background-color: transparent;
    }
        */

    .notification {
        padding: 20px;
        margin-bottom: 24px;

        > i {
            margin-right: 12px;
        }
    }
}

.btn-group-modal {
    margin-top: 20px;

    button {
        color: #fff;
        font-size: 1rem;
        font-weight: 700;
        min-width: 50px;
        min-height: 54px;
        margin-right: 20px;
        padding: 16px 30px;
        border: 2px solid $primary;
        border-radius: 4px;
        margin-top: 0 !important;
    }

    .cancel-btn {
        margin-top: 0;
        background-color: transparent !important;
        color: $primary !important;
        padding: 16px 30px;
    }
}