@import '../variables';
.textbox-form {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  // &:first-of-type {
  //     margin-top: 0;
  // }
  label {
    font-size: 1rem;
    color: $text-grey;
    font-family: $font-family-work-sans;
    letter-spacing: -0.01rem;
    line-height: 1.375rem;
    span {
      font-weight: 300;
    }
    + p {
      margin-bottom: 12px;
    }
  }
  &.email-input,
  &.password-input {
    label {
      font-weight: $bold;
    }
  }
  .textbox-form-input {
    position: relative;
    width: 100%;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.active-border {
      > input {
        border: 2px solid $primary;
      }
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
    input {
      width: 100%;
      font-family: inherit;
      margin: 0;
      border: none;
      border: 1px solid $text-grey;
      border-radius: 8px;
      position: relative;
      width: -webkit-fill-available;
      overflow: hidden;
      padding: 11px 45px 11px 15px;
      font-size: 1rem;
      line-height: 1.4;
      &::placeholder {
        color: $gray-dark-3;
      }
      width: 100%;
      box-sizing: border-box;
    }
    &:not(.textbox-form-input-info) {
      input {
        padding: 11px 15px;
      }
    }
    &.textbox-form-input-password {
      input {
        padding: 11px 45px 11px 15px;
      }
      button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .toggle-password-visibility-btn i {
        display: flex;
      }
    }
    &.error {
      input {
        border: 4px solid $error;
        border-radius: 8px;
        padding-right: 54px;
        &:active,
        &:focus {
          border-color: $error;
        }
      }
      .error-icon-container {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
    input {
      &:active,
      &:focus {
        border-color: $primary;
      }
    }
  }
  &.read-only {
    label {
      margin-bottom: 10px;
      font-size: 0.875rem;
    }
    input {
      padding: 0 !important;
      border: none;
    }
    .info-btn {
      top: -15px;
    }
    .textbox-form-input-info {
      input {
        width: calc(100% - 45px);
      }
    }
  }
  ol {
    li {
      list-style: disc;
      margin-left: 24px;
      font-family: $font-family-open-sans;
      color: $black-light;
    }
  }
}

.textbox-form-container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 33px;
  @include respond(phone) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

.textbox-form-container-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px 33px;

  .textbox-form {
    grid-column: 1 / 2;
  }
  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}
