@import "../variables";
div[class*="choose-id-form"] {
  &:not([class*="-layout"]) {
    padding: $layout-padding;
    display: flex;
    flex-direction: column;
    img {
      max-height: 200px;
      object-fit: contain;
      margin-bottom: 24px;
    }
    h1 {
      font-size: 1.375rem;
      font-family: $font-family-lato;
      text-align: center;
      strong {
        font-weight: 700 !important;
      }
      + p {
        margin-bottom: 24px;
      }
    }
    .login-button {
      margin-bottom: 16px;
      &:first-of-type {
        color: $black-dark-1;
      }
      &:nth-of-type(2) {
        color: $purple;
      }
    }
    .link-btn {
      &.choose-id-info {
        text-decoration: underline;
        color: $primary-blue;
        margin-bottom: 24px;
        text-align: left;
        margin-top: 0;
      }
    }
    .radiobutton-container {
      img {
        width: 111px;
        height: 77px;
        margin-bottom: 0;
      }
    }
    .notification {
      margin-bottom: 20px;
    }
  }
}
