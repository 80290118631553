@import "../partials/variables";

.wallet-consent-history-details {

	max-width: 1144px;

	.service-container {
		color: $text-grey;
		display: flex;
		align-items: center;

		img {
			width: 50px;
			height: 50px;
			cursor: pointer;
		}
	}

	.subtitle {
		color: $text-grey;
		font-size: 20px;
		font-weight: 400;
		line-height: 28px;
		letter-spacing: -0.01em;
		text-align: left;
		margin-right: 14px;

	}

	.wallet-consent-history-act {
		color: $text-grey;
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;

		@include respond(medium) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.btn-revoke {
			margin-top: 24px;
			@include respond(medium) {
				margin-top: 0px;
			}
		}

	
	}

	.details-table {
		margin-top: 30px;
	}

}

.wallet-history-revoke-modal {
	.title {
		font-size: 28px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.04em;
		text-align: left;
		color: $primary	;
		margin-bottom: 24px;
	}

	.subtitle{
		margin-bottom: 8px;
	}

	.revoke-btn {
		display: flex;
		& > button {
			margin-right: 24px;
		}
		.revoke{
			.lds-spinner-container {
				text-align: unset;
				margin-left: 0px;
			}
			@include respond(medium) {
				width: 165px;
			}
			
		}
		.go-back {
			@include respond(medium) {
				width:135px;
			}
		
		}
	}
}