@import '../partials/variables';

.wallet-consent-history-back {
  margin-top: 10px !important;
  margin-bottom: 20px !important;

  @include respond(medium) {
    margin-top: 6px !important;
    margin-bottom: 34px !important;
  }
}

.wallet-consent-history {
  max-width: 1160px;

  .title {
    margin-top: 4px;
  }

  .subtitle {
    color: $text-grey;
    font-family: $font-family-work-sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 16px;

    @include respond(medium) {
      margin-top: 44px;
      margin-bottom: 6px;
      line-height: 22px;
      letter-spacing: -0.015em;
    }
  }

  .desc {
    color: $text-grey;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.015em;
    text-align: left;

    .icon-container {
      display: flex;
      flex-direction: column;

      > div {
        line-height: 22px;
      }

      @include respond(medium) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      img {
        width: 50px;
        height: 50px;
        cursor: pointer;
        margin-top: 8px;

        @include respond(medium) {
          margin-top: 0px;
        }
      }
    }
  }

  .history-table {
    @include respond(medium) {
      margin-top: 4px;
    }
  }

  .table-card {
    margin-top: 20px;
  }
}

.wallet-history-key-definition {
  color: $text-grey;


  .btn-health-card-cancel {
    min-width: 117px !important;
  }

  .btn-health-card {
    min-width: 159px !important;
  }

  .title {
    font-family: $font-family-work-sans;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.04em;
    text-align: left;
    margin-bottom: 24px;
    line-height: 30.8px;

    @include respond(medium) {
      line-height: 28px;
    }
  }

  .subtitle {
    font-family: $font-family-work-sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    margin-bottom: 8px;
    p + p {
      margin-top: 8px;
    }
  }
}
