.choose-2FA,
.change-2FA {
  .icon-list-item {
    padding: 0;
  }
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .alternative-option-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include respond(medium) {
      gap: 24px;
    }
    .alternative-option {
      letter-spacing: -0.01rem;
      color: $text-grey;
      font-weight: 700;
      padding-left: 1px;
    }
  }
}

.email-auth {
  .title {
    margin-left: -6px;
    margin-bottom: 24px;
  }
  .text {
    &.lead-text {
      margin-bottom: 23px;
      letter-spacing: -0.0125rem;
    }
    margin-left: 2px;
    letter-spacing: -0.01rem;
    max-width: 946px;
  }
  .button-container-2 {
    margin-top: 32px;
    button {
      margin-top: 0;
      margin-bottom: 28px;
    }
  }
}

.change-2FA {
  &.authenticator-app-flow {
    .step-1 {
      .links-container {
        margin-top: 25px;
      }
    }
    .layout-content {
      .back-btn {
        margin-bottom: 31px;
      }
    }
  }
  &.email-auth {
    .title {
      margin-bottom: 24px;
    }
    &.layout-content {
      .back-btn {
        margin-bottom: 31px;
      }
    }
  }
  &.layout-content {
    max-width: 1280px;
    margin: 0 auto;
    @include respond(medium) {
      padding: 36px 68px;
    }
    .back-btn {
      margin-bottom: 36px;
    }
    .icon-info-item {
      padding-bottom: 0;
    }
    .digit-textbox {
      margin-top: 34px;
    }
  }
  .title {
    margin-bottom: 32px;
  }
  p.text.lead-text {
    margin-bottom: 24px;
  }
  p.text.lead-sub-text {
    letter-spacing: -0.01rem;
    margin-bottom: 32px;
  }
}

.account-ready-title {
  display: flex;
  gap: 16px;
  margin-bottom: 22px;
  align-items: center;
  h1 {
    margin: 0;
  }
}

// .account-ready {
p.text.withLink {
  display: unset;
  margin-top: 0;
}
p.text.withLink a {
  display: unset;
  margin-left: 0;
  margin-top: 0;
}
// }
.verify-id {
  .icon-list-item {
    align-items: center;
    p {
      margin-top: 1px;
    }
    i {
      align-items: center;
    }
  }
  .text {
    font-family: $font-family-work-sans;
  }
  .link-btn {
    font-size: 1rem;
    min-height: unset;
  }
  .verify-id-subtitle {
    font-weight: 700;
    margin: 16px 0;
  }
  .verify-id-buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
  }
  .verify-id-list {
    line-height: 22.4px;
    letter-spacing: -0.01em;

    margin-top: 8px;
    margin-bottom: 8px;
    li {
      list-style: disc;
      padding-left: 24px;
      list-style-position: inside;
      margin-bottom: 2px;

      p {
        display: inline;

        a {
          display: inline;
        }
      }

    }
  }

  @include respond(medium) {
    .verify-id-buttons {
      flex-direction: row;
    }
  }
}

.add-sms-learn-more {
  font-size: 1rem;
}

.code-form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .error-msg {
    color: $gray-dark-3;
  }
}
.code-sent {
  margin: 20px 0;
  font-style: italic;
  color: $gray-dark-3 !important;
  font-size: 0.875rem !important;
}

.code-sent-text {
  font-size: 16px;
  line-height: 22.4px;
  letter-spacing: -0.01em;
}

// AddSMSAuthPage.tsx
.sms-otp {
  .title {
    margin-bottom: 24px;
  }
  .icon-list-item {
    letter-spacing: -0.01em;
    line-height: 22.4px;

    i {
      padding-right: 8px;
      margin-top: -2px;
    }
  }

  .code-form {
    .digit-textbox {
      margin-top: 27px !important;
    }
  }

  .digit-textbox + .link-btn {
    margin: 0px;
    margin-top: 30px;
    margin-bottom: 12px;
  }

  .digit-textbox + .code-sent-text {
    margin-bottom: 32px;
  }

  .notification.notice-box {
    margin-top: 34px;
  }
}
