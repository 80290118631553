@import '../partials/variables';

.wallet-app {
  font-family: $font-family-work-sans;

  .wallet-change-email {
    margin-top: 18px;

    .change-email-subtitle{
      font-size: 20px;
      color: $black-light;
      margin-bottom: 8px;
      
      p {
        font-weight: 500;
      }
    }

    .change-email-desc {
      margin-bottom: 32px;
    }

    .input-container {
      max-width: 556px;
      margin-bottom: 32px;
    }

    .input-container-1 {
      max-width: 556px;
      margin-bottom: 8px;
    }

    .wallet-box-message{
      padding: 16px 32px;
      background-color: $red-light-2;
      color: $text-grey;
      margin-bottom: 32px;
    }
  }

  .profile-information-session {
    max-width: 752px;
    margin-bottom: 25px;

    .container-box-message {
      margin-top: 24px;
    }
  }

  .profile-info-group {
    border: 1px solid #ebebeb;
    border-width: 2px 0px 0px 0px;
  }

  .list-item {
    height: unset;
    padding: 15px 0px;
  }

  .title {
    font-size: 2.25rem;
    color: #232323;
    margin-bottom: 32px;
  }

  .subtitle {
    font-size: 2rem;
    margin-bottom: 17px;
    font-size: 32px;
    font-weight: 500;
    color: $black-light;
    font-family: $font-family-work-sans;
    letter-spacing: -0.04em;
  }
  .profile-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }

  .subtitle p {
    font-family: $font-family-work-sans;
    font-weight: 500;
    color: $black-light;
  }

  .notification {
    background-color: $light-blue-1;
    color: $text-grey;
    font-size: 0.875rem;
    padding: 16px;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    i {
      display: flex;
      align-items: center;
      margin-right: 0;
    }
  }

  .notification.error {
    background-color: $red-light-2;
  }

  .card {
    cursor: unset;
    padding: 32px 24px;
    min-height: 100px;
    margin: 0 0 12px 0;
    .reminder {
      margin: 0;
    }
  }

  .card h4 {
    font-size: 1.563rem;
  }

  .textbox-form {
    margin-top: 16px;
  }

  .textbox-form-container {
    margin-top: 24px;
    border: 1px solid #ebebeb;
    border-width: 0px 0px 2px 0px;
    padding-bottom: 16px;
  }

  .textbox-form .textbox-form-input input {
    border-radius: 8px;
  }

  .textbox-form-container-2 {
    border: 1px solid #ebebeb;
    border-width: 0px 0px 2px 0px;
    // margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    grid-gap: unset;
  }

  .btn-group {
    display: flex;
    gap: 24px;
    padding: 24px 0 8px;
  }

  .form-format-description {
    color: $gray-dark-3;
    font-size: 0.875rem;
    margin-top: 8px;
  }

  @include respond(medium) {
    .title {
      font-size: 4rem;
    }

    .wallet-profile-page-layout {
      display: flex;
      gap: 32px;
    }
    .wallet-profile-cards {
      flex: 1;
    }

    .wallet-profile-data {
      flex: 2;
    }
  }

  .list-item .description {
    margin: 0px;
  }

  .list-item .list-value {
    font-family: 'Work Sans';
    margin-top: 7px;
  }

  .list-item .list-action {
    margin-top: 0px;
  }

  .divider {
    max-width: 752px;
    border: 1px solid #ebebeb;
    border-width: 0px 0px 2px 0px;
  }

  .password-input ol {
    margin-top: 16px;
  }

  .profile-information-session.account-history {
    margin-bottom: 0px;
  }

  .account-history {
    .list-item {
      border-bottom: 0px;
    }

    .digital {
      font-size: 16px;
      font-family: 'Work Sans';
      font-weight: 400;
      line-height: 22.4px;
      letter-spacing: -0.01em;
      text-align: left;
    }

    .btn-account-history {
      margin: 0px 0px 24px 0px;
    }
    > .divider {
      margin-bottom: 32px;
    }
  }

  .delete-account {
    margin-top: 24px;
    @include respond(medium) {
      margin-top: 0;
    }
    .description {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      font-family: 'Work Sans';
      letter-spacing: -0.01em;
      a {
        display: inline-block;
        text-decoration: underline;
      }
    }
  }
}
