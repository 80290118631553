@import '../variables';
p {
  &.text {
    font-family: $font-family-work-sans;
    color: $text-grey;
    &.heading {
      @include respond(phone) {
        font-size: 1rem;
      }

      @include respond(medium) {
        font-size: 1.25rem;
      }
    }
    &.heading-strong {
      font-size: 16px;
      font-weight: 700;
    }
    &.error {
      color: $gray-dark-3;
      font-size: 0.875rem;
      margin-top: 8px;
      font-family: $font-family-open-sans;
    }
    &.withLink {
      display: flex;
      margin-top: 33px;
      a {
        margin-left: 5px;
        text-decoration: underline;
        color: $primary;
      }
    }
    + .text {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

h2 {
  letter-spacing: -0.04em;
}

h6 {
  margin-top: 32px;
  font-size: 1.25rem;
  font-family: $font-family-work-sans;
}
