@import '../variables';
.primary-btn {
  font-family: $font-family-work-sans;
  text-align: center;
  color: $white;
  background-color: $primary;
  border-radius: 4px;
  font-size: 1rem;
  padding: 12px 32px;
  font-size: 1rem;
  line-height: 1.375rem;
  // min-width: 116px;
  width: fit-content;
  &.disabled {
    background-color: #f3f4f6;
    color: #667085;
    pointer-events: none;
  }
  .lds-spinner {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-bottom: -15px;
    div {
      transform-origin: center;
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 5px;
        left: 9px;
        width: 2px;
        height: 6px;
        border-radius: 20%;
        background: white;
      }
    }
  }
  &.inverted {
    background: transparent;
    color: $primary;
    border: 2px solid $primary;
  }
  @include respond(medium) {
    width: fit-content;
  }
}

.primary-btn:hover {
  text-decoration: underline;
}

.secondary-btn {
  background-color: $white;
  border-radius: 4px;
  border: 2px solid $primary;
  padding: 10px 30px;
  color: $primary;
}

.secondary-btn:hover {
  background-color: $primary;
  border: none;
  color: white;
  border: 2px solid transparent;
  text-decoration: underline;
}

.button-container-2 {
  display: flex;
  margin-top: 30px;
  gap: 24px;
}

.button-container-3 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
}
.button-container-3 .primary-btn {
  padding: 12px 32px;
}

@include respond(phone) {
  .primary-btn {
    padding: 10px 24px;
  }
}

@include respond(medium) {
  .button-container-3 {
    flex-direction: row;
  }
  .button-container-3 .primary-btn {
    width: unset;
  }
}
