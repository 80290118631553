.stepper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin: 16px 0 38px 0;

  @include respond(phone) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .label {
    position: relative;
    display: inline-block;

    span {
      padding: 4px 8px;
      color: $text-grey;
      border-radius: 100px;
      font-size: 0.875rem;
      font-family: $font-family-work-sans;
      border: 1px solid #000;
      letter-spacing: -0.04em;
    }
  }

  .steps-container {
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 23px;

    @include respond(phone) {
      width: 100%;
    }

    .step-container {
      display: flex;
      align-items: center;
      width: 100%;

      &.last-step {
        width: auto;
      }

      .step {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #777777;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        flex-shrink: 0;
        font-family: $font-family-work-sans;

        &.complete {
          background-color: #2761aa;
        }

        &.current {
          background-color: #2761aa;
        }

        &.incomplete {
          background-color: #ebebeb;
          color: #777;
        }
      }

      .line {
        height: 4px;
        width: 100%;
        background-color: #ebebeb;

        &.complete {
          background-color: #2761aa;
        }

        &.current {
          background-color: $red;
        }

        &.incomplete {
          background-color: #ebebeb;
        }
      }
    }
  }
}

.no-back .stepper {
  margin-bottom: 48px;
}
