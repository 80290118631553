.authenticator-app-flow-backbutton {
  margin-top: 12px !important;
  margin-bottom: 24px !important;
  
  @include respond(medium) {
    margin-top: 0px !important;
    margin-bottom: 31px !important;

  }
}
.authenticator-app-flow {
  &.step-1 .links-container {
    margin: 22px 0 32px 0;
  }
  &.step-2 {
    .title {
      margin-bottom: 24px;
    }
    .button-container-2 {
      margin-top: 8px;
    }
  }

  &.step-3 {
    .title {
      margin-bottom: 16px;
      margin-top: 0px;
      letter-spacing: -0.04em;
      line-height: 39.6px;

      @include respond(medium) {
        margin-bottom: 24px;
        margin-top: 2px;
        letter-spacing: -0.04em;
        line-height: 57.6px;
      }

    }
    .subtitle {
      margin-top: 0px;
      margin-bottom: 30px;
    }
    & > .icon-list-item {
      margin-bottom: 21px;
      align-items: flex-start;

      i {
        margin-top: 4px;
        margin-left: -1px;
      }
      .text {
        max-width: 720px;
      }

    }
    & > .links-container {
      margin-bottom: 8px;
    }
    .digit-textbox {
      margin-bottom: 16px;
    }
  }
  .back-btn {
    font-weight: 400;
  }

  .icon-list-item {
    align-items: center;

    p {
      a {
        display: inline-block;
        color: $primary;
        text-decoration: underline;
      }
    }
  }

  .links-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .icon-list-item {
      align-items: center;
    }

    .link-btn {
      font-weight: 400;
    }

    .show-me-btn {
      font-size: 1rem;
      color: $primary;
      text-decoration: underline;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .button-container-2 {
    margin-top: 0;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    gap: 24px;

    .subtitle {
      margin-top: 0px;
    }

    .qr-code {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 10px;
      align-items: center;
      flex-shrink: 0;
      margin: 8px 0;

      @include respond(medium) {
        flex-direction: row;
        gap: 58px;
        margin-bottom: 16px;
      }

      .instruction-box {
        max-width: 228px; //260 -16 - 16
        border-radius: 8px;
        background: #ededed;
        padding: 16px;
        padding-bottom: 20px;
        width: calc(100% - 24px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        button {
          font-size: 1rem;
          line-height: 1.375rem;
          letter-spacing: -0.01rem;
          color: $primary;
          text-decoration: underline;
          font-family: $font-family-work-sans;
        }

        p:first-child {
          font-size: 0.875rem;
          line-height: 1.25rem;
          letter-spacing: -0.00875rem;
        }

        > p {
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: -0.01rem;
          text-align: center;
          overflow-wrap: anywhere;
        }

        .icon-list-item {
          align-items: center;
          gap: 6px;
          justify-content: center;

          > i {
            order: 1;
            padding-right: 0;
            min-width: 13px;
          }
        }
      }
    }

    @include respond(phone) {
      flex-direction: column;
    }
  }

  .content-body-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @include respond(phone) {
      flex-direction: column;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .icon-list-item {
        &:first-of-type {
          padding-top: 0;
        }
      }

      .desc {
        margin-top: 24px;
      }
    }

    .or-text {
      margin: 16px 0 16px 32px;
    }

    .right {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 260px;
      width: 100%;
      border: 1px solid black;

      @include respond(phone) {
        max-width: 100%;
      }

      > svg {
        width: 175px;
        height: 175px;
      }
    }
  }

  .subtitle {
    font-size: 1.25rem;
    line-height: 1.8rem;
    letter-spacing: -0.0125rem;
    margin: 24px 0 32px;
  }

  .authenticator-code-form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .digit-textbox {
      margin-top: 24px !important;

      .digit-textbox-6 div input {
        padding-bottom: 16px !important;
      }

      &.error {
        input {
          caret-color: $red !important;
          border-color: $red !important;
        }
      }
    }

    input {
      color: #000 !important;
      caret-color: #000 !important;
      border-color: #000 !important;
    }

    .authenticator-code-form-error-message {
      margin-bottom: 24px;
      color: $text-light-grey !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      letter-spacing: -0.01em;

    }
  }
}

.notice-box {
  margin-top: 32px;
  background: #fef2e6;
  padding: 16px 18px;
  color: $text-grey;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.01rem;
}

@include respond(medium) {
  .notice-box {
    padding: 16px 32px;
  }
}

.authenticator-modal {
  h2 {
    font-size: 1.75rem !important;
    margin-bottom: 12px;
    color: $text-grey !important;
    font-family: $font-family-work-sans !important;
  }

  .icon-list-item {
    align-items: flex-start;
    max-width: 520px;

    i {
      margin-top: 0px;
    }

    .text {
      line-height: 22px;
      letter-spacing: -0.01em;
    }
  }
}

.setup-sms-text {
  max-width: 716px;
  margin-top: 2px;
  line-height: 1.375rem;
  letter-spacing: -0.01rem;
}
