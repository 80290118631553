@import '../variables';
.digit-textbox {
  + .link-btn {
    margin-top: 30px;
    margin-bottom: 36px;
  }
  @include respond(phone) {
    + .link-btn {
      margin: 24px 0 16px;
    }
  }
}
