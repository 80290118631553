@import 'partials/plugins/reset';
@import 'partials/mixins';
@import 'partials/variables';
@import 'partials/global';
@import 'partials/plugins/toggle';
// components
@import 'partials/components/accessForm';
@import 'partials/components/accordian';
@import 'partials/components/button';
@import 'partials/components/checkbox';
@import 'partials/components/chooseidform';
@import 'partials/components/consentform';
@import 'partials/components/ctarow';
@import 'partials/components/ctarowbutton';
@import 'partials/components/dropdown';
@import 'partials/components/editDrawer';
@import 'partials/components/footer';
@import 'partials/components/form';
@import 'partials/components/header';
@import 'partials/components/iconlistitem';
@import 'partials/components/iconinfoitem';
@import 'partials/components/infobtn';
@import 'partials/components/layout';
@import 'partials/components/linkbutton';
@import 'partials/components/loginbutton';
@import 'partials/components/loading';
@import 'partials/components/loginform';
@import 'partials/components/menu';
@import 'partials/components/modal';
@import 'partials/components/notification';
@import 'partials/components/passcodeform';
@import 'partials/components/passcodeinput';
@import 'partials/components/radiobtn';
@import 'partials/components/reviewAccount';
@import 'partials/components/spinner';
@import 'partials/components/card';
@import 'partials/components/text';
@import 'partials/components/textbox';
@import 'partials/components/title';
@import 'partials/components/tosform';
@import 'partials/components/verifiedmebutton';
@import 'partials/components/wallet';
@import 'partials/components/listItem';
@import 'partials/components/table';
@import 'partials/components/tableCard';
@import 'partials/components/accordion';
@import 'partials/components/boxMessage';

@import 'partials/components/stepper';

// views
@import 'views/pages';
@import 'views/profileInformationPage';
@import 'views/authenticatorPages';
@import 'views/2FAPages';
@import 'views/walletPages';
@import 'views/walletConsentPage';
@import 'views/walletConsentHistoryPage';
@import 'views/walletConsentHistoryDetailsPage';
@import 'views/accountSetupHelpPage';
@import 'views/identityVerificationSubmissionPage';

// fonts
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
